import React, { ComponentPropsWithoutRef } from "react"
import { Link } from "gatsby"
import { ArrowRight } from "react-feather"
import { mergeClasses } from "utils"
import { TranslationKey } from "types/translation"
import { Trans, TransProps } from "gatsby-plugin-react-i18next"

type P = ComponentPropsWithoutRef<"div"> & {
	label: TranslationKey
	title: TranslationKey
	description: TranslationKey
	link: string
	image: string
	showLink?: boolean
	reverse?: boolean
	showLabel?: boolean
	descriptioni18?: TransProps<"material-informatics-page.aspects">
}

export type SectionCardProps = P

export const SectionCard = ({
	label,
	title,
	description,
	link,
	image,
	showLink = true,
	reverse = false,
	className = "",
	showLabel = true,
	descriptioni18 = {},
	...props
}: P) => {
	return (
		<div
			className={mergeClasses({
				classes: [
					"grid grid-cols-7 sm:justify-between items-center md:gap-20 gap-8",
					className,
				],
			})}
			{...props}
		>
			<div
				className={`flex flex-col gap-4 text-left col-span-7 md:col-span-4 ${
					reverse ? "md:order-1" : "md:order-[-1]"
				}`}
			>
				{showLabel && (
					<p className="tracking-wider bg-gradient-to-r from-theme-orange to-theme-blue bg-clip-text text-transparent text-xl max-w-max">
						<Trans i18nKey={`${label}`} />
					</p>
				)}

				<p className="text-4xl tracking-tight text-gray-900 sm:text-5xl">
					<Trans i18nKey={`${title}`} />
				</p>

				<p className="text-gray-900 sm:text-lg md:text-xl">
					<Trans i18nKey={`${description}`} {...descriptioni18} />
				</p>

				{showLink && (
					<Link to={link} className="flex items-center gap-2 text-theme-blue">
						<span>
							<Trans i18nKey="common.cta.learn-more" />
						</span>
						<ArrowRight size={16} />
					</Link>
				)}
			</div>

			<img
				alt={title}
				className={`w-full rounded-lg overflow-hidden col-span-7 md:col-span-3 ${
					reverse ? "md:order-[-1]" : "md:order-1"
				}`}
				src={image}
			/>
		</div>
	)
}
