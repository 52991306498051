import React from "react"
import { Container } from "components/library"
import DataIllustration from "images/products/material-informatics/data.jpg"
import ROIIllustration from "images/products/material-informatics/roi.jpg"
import DigitalizationImage from "images/products/material-informatics/digitalization.jpg"
import AIImage from "images/products/material-informatics/ai.jpg"
import { graphql } from "gatsby"
import { SecureAnalysisIllustration } from "components/library/illustrations/secure-analysis-illustration";
import { SectionCard, SectionCardProps } from "components/common/section-card"
import { MiniNavbar } from "components/common/mini-navbar"
import { HeroLayout } from "components/common/hero-layout"
import { CtaBlock } from "components/common/cta-block"
import { getMaterialInformaticsLink } from "utils/general/links"

const aspects: SectionCardProps[] = [
	{
		label: "common.words.empty-space",
		title: "material-informatics-page.aspects.1.title",
		description: "material-informatics-page.aspects.1.description",
		image: DataIllustration,
		link: `${getMaterialInformaticsLink(process.env.GATSBY_LANGUAGE_SELECTED)}`,
		id: "data",
	},
	{
		label: "common.words.empty-space",
		title: "material-informatics-page.aspects.2.title",
		description: "material-informatics-page.aspects.2.description",
		image: ROIIllustration,
		link: `${getMaterialInformaticsLink(process.env.GATSBY_LANGUAGE_SELECTED)}`,
		id: "roi",
	},
	{
		label: "common.words.empty-space",
		title: "material-informatics-page.aspects.3.title",
		description: "material-informatics-page.aspects.3.description",
		image: DigitalizationImage,
		link: `${getMaterialInformaticsLink(process.env.GATSBY_LANGUAGE_SELECTED)}`,
		id: "digitalization",
	},
	{
		label: "common.words.empty-space",
		title: "material-informatics-page.aspects.4.title",
		description: "material-informatics-page.aspects.4.description",
		image: AIImage,
		link: `${getMaterialInformaticsLink(process.env.GATSBY_LANGUAGE_SELECTED)}`,
		id: "ai",
	},
];

const MaterialInformatic = () => {
	return (
		<main className="flex flex-col gap-20 pt-12 sm:pt-8">
			<Container className="hidden sm:flex flex-col gap-28">
				<MiniNavbar
					title="common.words.material-informatics"
					links={[
						{ label: "common.words.data", path: "#data" },
						{ label: "common.words.roi", path: "#roi" },
						{ label: "common.words.digitalization", path: "#digitalization" },
						{ label: "common.words.ai", path: "#ai" },
					]}
				/>
			</Container>

			<HeroLayout
				title="material-informatics-page.hero.title"
				titleClass="bg-gradient-to-r from-theme-orange to-theme-blue bg-clip-text text-transparent"
				content="material-informatics-page.hero.description"
				illustration={SecureAnalysisIllustration}
			/>

			<Container className="flex flex-col gap-20 py-16">
				{aspects.map((aspect, i) => {
					return (
						<SectionCard
							key={aspect.title}
							showLabel={false}
							reverse={(i + 1) % 2 !== 0}
							className="scroll-m-52"
							descriptioni18={{
								components: {
									anchor: <a className="anchor" />,
								},
							}}
							{...aspect}
						/>
					)
				})}
			</Container>

			<CtaBlock />
		</main>
	)
}

export default MaterialInformatic

// FOR LANGUAGE TRANSLATION
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`
